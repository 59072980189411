<script setup lang="ts">
</script>

<template>
  <main class="space-y-24">
    <section class="space-y-6">
      <div class="space-y-6">
        <h2 class="font-display font-semibold dark:text-gold-500 text-gold-700 text-2xl">Overview</h2>
        <p>We strive to empower you with complete control over every aspect of your experience. Our comprehensive Settings pages allow you to customize individual features and functionalities, enabling you to craft an experience tailored to your preferences and desires.</p>
        <p>Here are some quick links to our most commonly used settings pages:</p>
        <ul class="flex flex-wrap gap-6">
          <li>
            <router-link class="underline" to="/settings/profile/details">Profile Details</router-link>
          </li>
          <li>
            <router-link class="underline" to="/settings/profile/about">Profile About</router-link>
          </li>
          <li>
            <router-link class="underline" to="/settings/profile/photo">Profile Photo</router-link>
          </li>
          <li>
            <router-link class="underline" to="/settings/privacy/settings">Privacy Settings</router-link>
          </li>
        </ul>
      </div>
    </section>
    <section class="space-y-6">
      <h3 class="font-display font-semibold text-xl">Recent Settings Updates</h3>
      <ul>
        <li>March 2025: Added <router-link class="underline" to="/settings/preferences">Feature Previews</router-link> to Preferences</li>
        <li>December 2024: Added Mentions to <router-link class="underline" to="/settings/notifications">Notifications Settings</router-link></li>
        <li>November 2024: Added <router-link class="underline" to="/settings/privacy/starterpacks">Starter Packs</router-link> to Privacy Settings</li>
        <li>November 2024: Added <router-link class="underline" to="/settings/account/standing">Standing</router-link> overview to Account Settings</li>
        <li>August 2024: Enabled <router-link class="underline" to="/settings/privacy/circles">Circles Settings</router-link></li>
        <li>August 2024: Added Relationship Requests to <router-link class="underline" to="/settings/notifications">Notifications Settings</router-link></li>
        <li>July 2024: Added Relationship Requests to <router-link class="underline" to="/settings/privacy/settings">Privacy Settings</router-link></li>
        <li>July 2024: Enabled <router-link class="underline" to="/settings/relationships">Relationships Settings</router-link></li>
      </ul>
    </section>
  </main>
</template>
