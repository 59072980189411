<script setup lang="ts">
import GroupHeader from "@/components/groups/Header.vue"
import { usePlatformStore } from "@/stores/Platform"
import { useUserStore } from "@/stores/User"
import GroupNav from "@/components/core/Nav.vue"
import { storeToRefs } from "pinia"
import { useRoute, useRouter } from "vue-router"
import { ref, onBeforeMount, onMounted, provide, computed } from "vue"
import API from "@/api/api"

const PlatformStore = usePlatformStore()
const UserStore = useUserStore()
const { loading } = storeToRefs(PlatformStore)
const { roles } = storeToRefs(UserStore)
const Route = useRoute()
const Router = useRouter()

const Group = ref<any>({})
provide("Group", Group)

const groupId = ref<string>("")
provide("groupId", groupId)

const slug = ref<string>(Route.params.slug as string)
provide("slug", slug)

const type = ref<string>("")
function setType(value: string) {
  type.value = value
}
provide("type", {type, setType})

const isMember = ref<boolean>(false)
function setIsMember(value: boolean) {
  isMember.value = value
}
provide("isMember", {isMember, setIsMember})

const isSubscribed = ref<boolean>(false)
function setIsSubscribed(value: boolean) {
  isSubscribed.value = value
}
provide("isSubscribed", {isSubscribed, setIsSubscribed})

const isModerator = ref<boolean>(false)
function setIsModerator(value: boolean) {
  isModerator.value = value
}
provide("isModerator", {isModerator, setIsModerator})

const isOwner = ref<boolean>(false)
function setIsOwner(value: boolean) {
  isOwner.value = value
}
provide("isOwner", {isOwner, setIsOwner})

const isQueued = ref<boolean>(false)
function setIsQueued(value: boolean) {
  isQueued.value = value
}
provide("isQueued", {isQueued, setIsQueued})

const isBanned = ref<boolean>(false)
function setIsBanned(value: boolean) {
  isBanned.value = value
}
provide("isBanned", {isBanned, setIsBanned})

const isTimedOut = ref<boolean>(false)
function setIsTimedOut(value: boolean) {
  isTimedOut.value = value
}
provide("isTimedOut", {isTimedOut, setIsTimedOut})

const isWarned = ref<boolean>(false)
function setIsWarned(value: boolean) {
  isWarned.value = value
}
provide("isWarned", {isWarned, setIsWarned})

const hasPostQueue = ref<boolean>(false)
function setHasPostQueue(value: boolean) {
  hasPostQueue.value = value
}
provide("hasPostQueue", {hasPostQueue, setHasPostQueue})

const hasMemberQueue = ref<boolean>(false)
function setHasMemberQueue(value: boolean) {
  hasMemberQueue.value = value
}
provide("hasMemberQueue", {hasMemberQueue, setHasMemberQueue})

const hasPersonals = ref<boolean>(false)
function setHasPersonals(value: boolean) {
  hasPersonals.value = value
}
provide("hasPersonals", {hasPersonals, setHasPersonals})

const personalsOnly = ref<boolean>(false)
function setPersonalsOnly(value: boolean) {
  personalsOnly.value = value
}
provide("personalsOnly", {personalsOnly, setPersonalsOnly})

const multiBoard = ref<boolean>(false)
function setMultiBoard(value: boolean) {
  multiBoard.value = value
}
provide("multiBoard", {multiBoard, setMultiBoard})

const boards = ref<any[]>([])
function setBoards(value: any[]) {
  boards.value = value
}
provide("boards", {boards, setBoards})

const memberCount = ref<number>(0)
function incrementMemberCount() { memberCount.value++ }
function decrementMemberCount() { memberCount.value-- }
provide("memberCount", {memberCount, incrementMemberCount, decrementMemberCount})

const pinnedPosts = ref<string[]>([])
function setPinnedPosts(value: string[]) {
  pinnedPosts.value = value
}
provide("pinnedPosts", {pinnedPosts, setPinnedPosts})

const canView = computed(() => {
  if (type.value !== 'private') return true
  if (type.value === 'private' && isMember.value) return true
  return false
})
provide("canView", canView)

const canPin = computed(() => {
  if (!isModerator.value) return false
  if (pinnedPosts.value.length  >= 4) return false
  return true
})
provide("canPin", canPin)

const canPost = computed(() => {
  if (type.value !== 'official' && isMember.value) return true
  if (type.value === 'official' && isModerator.value) return true
  return false
})
provide("canPost", canPost)

onBeforeMount(async () => {
  PlatformStore.setLoading(true)
})

const groupFetchedAndSet = ref(false)

onMounted(async () => {
  await getGroup()
  groupId.value = Group.value._id
  type.value = Group.value.type
  isMember.value = Group.value.isMember || false
  isSubscribed.value = Group.value.isSubscribed || false
  isModerator.value = Group.value.isModerator || false
  isOwner.value = Group.value.isOwner || false
  isQueued.value = Group.value.isQueued || false
  isBanned.value = Group.value.isBanned || false
  isTimedOut.value = Group.value.isTimedOut || false
  isWarned.value = Group.value.isWarned || false
  memberCount.value = Group.value.memberCount || 0
  // hasPostQueue.value = Group.value.hasPostQueue
  hasMemberQueue.value = Group.value.hasMemberQueue || false
  hasPersonals.value = Group.value.hasPersonals || false
  personalsOnly.value = Group.value.personalsOnly || false
  multiBoard.value = Group.value.isMultiBoard || false
  boards.value = Group.value.boards || []
  pinnedPosts.value = Group.value.pinnedPosts || []
  document.title = `${Group.value.name} - Submit`
  PlatformStore.setLoading(false)
})

const icons: any = {
  default: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>`,
  help: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>`,
  bug: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c53 0 96 43 96 96l0 3.6c0 15.7-12.7 28.4-28.4 28.4l-135.1 0c-15.7 0-28.4-12.7-28.4-28.4l0-3.6c0-53 43-96 96-96zM41.4 105.4c12.5-12.5 32.8-12.5 45.3 0l64 64c.7 .7 1.3 1.4 1.9 2.1c14.2-7.3 30.4-11.4 47.5-11.4l112 0c17.1 0 33.2 4.1 47.5 11.4c.6-.7 1.2-1.4 1.9-2.1l64-64c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-64 64c-.7 .7-1.4 1.3-2.1 1.9c6.2 12 10.1 25.3 11.1 39.5l64.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c0 24.6-5.5 47.8-15.4 68.6c2.2 1.3 4.2 2.9 6 4.8l64 64c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0l-63.1-63.1c-24.5 21.8-55.8 36.2-90.3 39.6L272 240c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 239.2c-34.5-3.4-65.8-17.8-90.3-39.6L86.6 502.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l64-64c1.9-1.9 3.9-3.4 6-4.8C101.5 367.8 96 344.6 96 320l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64.3 0c1.1-14.1 5-27.5 11.1-39.5c-.7-.6-1.4-1.2-2.1-1.9l-64-64c-12.5-12.5-12.5-32.8 0-45.3z"/></svg>`,
  code: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>`
}

const boardsNavItems = computed(() => {
  return [
    ...(multiBoard.value && boards.value.length > 0 ? [
      ...boards.value.map((board: any) => ({
      name: board.name,
      icon: icons[board.icon],
      to: board.isDefault ? `/groups/${Route.params.slug}` : `/groups/${Route.params.slug}/b/${board.slug}`,
      category: board.isDefault ? "discussions" : board.slug,
      subsections: []
    }))
  ] : [
    {
      name: personalsOnly.value ? "Personals" : "Discussions",
      icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H448c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H309.3L185.6 508.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3V416H64c-35.3 0-64-28.7-64-64V64zm152 80c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H152zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H264c13.3 0 24-10.7 24-24s-10.7-24-24-24H152z"/></svg>`,
      to: `/groups/${Route.params.slug}`,
      category: "discussions",
      subsections: []
    },
    ... (hasPersonals.value && !personalsOnly.value ? [
      {
        name: "Personals",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>`,
        to: `/groups/${Route.params.slug}/personals`,
        category: "personals",
        subsections: []
      }
    ] : [])
    ])
  ]
})

const navItems = computed(() => {
  return [
    {
      name: "About",
      icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/></svg>`,
      to: `/groups/${Route.params.slug}/about`,
      category: "about",
      subsections: []
    },
    ...boardsNavItems.value,
    ... (isMember.value && hasPostQueue.value ? [
      {
        name: "Queue",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>`,
        to: `/groups/${Route.params.slug}/queue`,
        category: "postQueue",
        subsections: []
      }
    ] : []),
    ... (type.value !== 'private' || (type.value === 'private' && isMember.value) ? [
      {
        name: "Members",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512"><path d="M72 88a56 56 0 1 1 112 0A56 56 0 1 1 72 88zM64 245.7C54 256.9 48 271.8 48 288s6 31.1 16 42.3V245.7zm144.4-49.3C178.7 222.7 160 261.2 160 304c0 34.3 12 65.8 32 90.5V416c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V389.2C26.2 371.2 0 332.7 0 288c0-61.9 50.1-112 112-112h32c24 0 46.2 7.5 64.4 20.3zM448 416V394.5c20-24.7 32-56.2 32-90.5c0-42.8-18.7-81.3-48.4-107.7C449.8 183.5 472 176 496 176h32c61.9 0 112 50.1 112 112c0 44.7-26.2 83.2-64 101.2V416c0 17.7-14.3 32-32 32H480c-17.7 0-32-14.3-32-32zm8-328a56 56 0 1 1 112 0A56 56 0 1 1 456 88zM576 245.7v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM320 32a64 64 0 1 1 0 128 64 64 0 1 1 0-128zM240 304c0 16.2 6 31 16 42.3V261.7c-10 11.3-16 26.1-16 42.3zm144-42.3v84.7c10-11.3 16-26.1 16-42.3s-6-31.1-16-42.3zM448 304c0 44.7-26.2 83.2-64 101.2V448c0 17.7-14.3 32-32 32H288c-17.7 0-32-14.3-32-32V405.2c-37.8-18-64-56.5-64-101.2c0-61.9 50.1-112 112-112h32c61.9 0 112 50.1 112 112z"/></svg>`,
        to: `/groups/${Route.params.slug}/members`,
        category: "members",
        subsections: []
      }
    ] : []),
    ... (isMember.value && isModerator.value ? [
      {
        name: "Moderation",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/></svg>`,
        category: "moderation",
        subsections: [
          {
            name: "Messages",
            subsection: "messages",
            to: `/groups/${Route.params.slug}/moderation/messages`
          },
          {
            name: "Reports",
            subsection: "reports",
            to: `/groups/${Route.params.slug}/moderation/reports`
          },
          {
            name: "Activity",
            subsection: "activity",
            to: `/groups/${Route.params.slug}/moderation/activity`
          },
          {
            name: "Members",
            subsection: "members",
            to: `/groups/${Route.params.slug}/moderation/members`
          },
          ... (hasPostQueue.value ? [
            {
              name: "Post Queue",
              subsection: "postqueue",
              to: `/groups/${Route.params.slug}/moderation/postqueue`
            }
          ] : []),
          ... (hasMemberQueue.value ? [
            {
              name: "Member Queue",
              subsection: "memberqueue",
              to: `/groups/${Route.params.slug}/moderation/memberqueue`
            }
          ] : [])
        ]
      }
    ] : []),
    ... (isMember.value ? [
      {
        name: "Settings",
        icon: `<svg class="w-4" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>`,
        category: "settings",
        subsections: [
          {
            name: "Profile",
            subsection: "profile",
            to: `/groups/${Route.params.slug}/settings/profile`
          },
          {
            name: "Notifications",
            subsection: "notifications",
            to: `/groups/${Route.params.slug}/settings/notifications`
          },
          ... (isModerator.value ? [{
            name: "Moderation",
            subsection: "moderation",
            to: `/groups/${Route.params.slug}/settings/moderation`
          }] : []),
          ... (isOwner.value ? [{
            name: "Group",
            subsection: "group",
            to: `/groups/${Route.params.slug}/settings/group`
          }] : [])
        ]
      }
    ]: [])
  ]
})

async function getGroup() {
  try {
    const response = await API().get(`/groups/g/${Route.params.slug}`)
    Group.value = response.data.data
    groupFetchedAndSet.value = true
  } catch (err) {
    return Router.push("/notfound")
  }
}
</script>

<template>
  <main v-if="!loading">
    <GroupHeader :group="Group" v-if="groupFetchedAndSet" />
    <div class="px-2">
      <div class="container mx-auto Page Groups">
        <aside>
          <GroupNav :nav-items="navItems" :slice="3" v-if="groupFetchedAndSet" />
        </aside>
        <router-view v-slot="{ Component }">
          <component :is="Component" v-if="groupFetchedAndSet" />
        </router-view>
      </div>
    </div>
  </main>
</template>
