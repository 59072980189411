<script setup lang="ts">
import { generateUniqueSessionId, getSignals } from "@/composables/Sessions.js"
import AuthWrapper from "@/components/core/AuthWrapper.vue"
import { provide, ref, reactive, onMounted } from "vue"
import TestForm from "@/components/auth/Test.vue"
import Create from "@/components/auth/Create.vue"
import { useRoute } from "vue-router"
import API from "@/api/api"

const Route = useRoute()

const initialValues = reactive({
  email: "",
  username: ""
})

const state = ref<string>("checking") // email, create
const challenge = ref<string>("")
const challengeExpiry = ref<Date>(new Date())
const challengeHasExpired = ref<boolean>(false)
const sessionId = ref<string | undefined>(undefined)
const code = ref<string>("")

provide("initialValues", initialValues)
provide("challenge", challenge)
provide("challengeExpiry", challengeExpiry)
provide("challengeHasExpired", challengeHasExpired)
provide("sessionId", sessionId)
provide("code", code)

onMounted(async () => {
  sessionId.value = await generateUniqueSessionId()
  code.value = Route.query.code as string
  if (!code.value || code.value === "") {
    state.value = "email"
    return
  }
  try {
    await checkCode(code.value)
    state.value = "create"
  } catch (err) {
    state.value = "email"
    window.history.replaceState({}, document.title, window.location.pathname)
  }
})

function checkCode(code: string) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await API(false).post("/verify", {
        code: code,
        session: sessionId.value,
        ua: navigator.userAgent,
        signals: getSignals()
      })
      initialValues.email = response.data.data.email
      initialValues.username = response.data.data.username
      challenge.value = response.data.data.challenge
      challengeExpiry.value = new Date(response.data.data.challengeExpiresAt)
      const expiryMs = challengeExpiry.value.getTime() - new Date().getTime()
      setTimeout(() => {
        challengeHasExpired.value = true
      }, expiryMs)
      resolve(response.data.data)
    } catch (err) {
      reject(err)
    }
  })
}
</script>

<template>
  <AuthWrapper>
    <template v-if="state === 'checking'">
      <p class="text-sm leading-6 animate-pulse">Just a moment&hellip;</p>
    </template>
    <TestForm v-if="state === 'email'" />
    <Create v-if="state === 'create'" />
  </AuthWrapper>
</template>
