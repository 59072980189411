<script setup lang="ts">
import { ref, computed, onBeforeMount, onMounted, onUnmounted, inject, reactive } from "vue"
import ChangeAudience from "@/components/core/ChangeAudience.vue"
import LoadIndicator from "@/components/misc/LoadIndicator.vue"
import AddRemove from "@/components/collections/AddRemove.vue"
import Comments from "@/components/comments/Comments.vue"
import ReactV2 from "@/components/reactions/ReactV2.vue"
import { usePlatformStore } from "@/stores/Platform"
import Editor from "@/components/editor/Editor.vue"
import Tags from "@/components/input/TagsRaw.vue"
import { useRoute, useRouter } from "vue-router"
import Label from "@/components/input/Label.vue"
import { useUserStore } from "@/stores/User"
import D from "@/composables/TimeDisplay"
import { storeToRefs } from "pinia"
import { validate } from "uuid"
import API from "@/api/api"

const editorOptions = {
  bold: true,
  italic: true,
  strike: true,
  link: true,
  mentions: true
}

const route = useRoute()
const router = useRouter()

const Platform = usePlatformStore()
const User = useUserStore()
const { id, roles, circles } = storeToRefs(User)

const profileData: any = inject("profileData")
const context = ref(route.query.ctx || "default")
const mediaID = ref(route.params.id)
const media = ref<any>(undefined)
const audience = ref<string | undefined>(undefined)
const nav = ref<any>({})
const content = reactive({
  contentAuthor: "",
  contentId: "",
  contentType: "",
  contentAudience: ""
})
const deleting = ref(false)
const editing = ref(false)
const updating = ref(false)
const raw = ref("")

const description = ref<string>("")
const tags = ref<string[]>([])

const isOwner = computed(() => media.value.user === id.value)

const collectionModal = ref(false)
const collectionAction = ref<'add' | 'remove' | undefined>(undefined)
const toggleCollectionModal = (action?: 'add' | 'remove') => {
  if (action) {
    collectionAction.value = action
  }
  collectionModal.value = !collectionModal.value
}

const changeAudienceModal = ref(false)
const toggleChangeAudienceModal = () => {
  changeAudienceModal.value = !changeAudienceModal.value
}

const visibilityLabel = computed(() => {
  if (!audience.value) return 'Unknown'
  switch (audience.value) {
    case 'public':
      return 'Everyone'
    case 'followers':
      return 'Followers'
    case 'relationships':
      return 'Relationships'
    default:
      if (validate(audience.value)) {
        const circle = circles.value.find((c: any) => c.code === audience.value)
        if (isOwner.value) {
          if (circle) return `the ${circle.name} circle`
        } else {
          return `a circle`
        }
      }
      return audience.value
  }
})

const collection = ref<any>(undefined)

const processedContent = computed(() => {
  if (!description.value) return ""

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = description.value

  // Find all mention spans
  const mentions = tempDiv.querySelectorAll('span[data-type="mention"]')

  // Replace each mention span with a special anchor tag
  mentions.forEach(mention => {
    const username = mention.getAttribute('data-label')
    if (username) {
      const anchor = document.createElement('a')
      // Set actual URL in href
      anchor.setAttribute('href', `/${username}`)
      anchor.setAttribute('class', 'mention')
      // Add click prevention
      anchor.setAttribute('onclick', 'event.preventDefault()')
      anchor.setAttribute('data-username', username)
      anchor.textContent = `@${username}`
      mention.parentNode?.replaceChild(anchor, mention)
    }
  })

  return tempDiv.innerHTML
})

// Add click handler function
function handleMentionClick(event: MouseEvent) {
  const target = event.target as HTMLElement
  if (target.classList.contains('mention')) {
    const username = target.getAttribute('data-username')
    if (username) {
      router.push(`/${username}`)
    }
  }
}

onBeforeMount(async () => {
  await getMedia()
})

onMounted(() => {
  window.addEventListener("keydown", keydownHandler)
})

onUnmounted(() => {
  window.removeEventListener("keydown", keydownHandler)
})

function updateRaw(newRaw: string) {
  raw.value = newRaw.trim()
}

const isAdmin = computed(() => {
  return roles.value.includes("admin")
})

const nextLink = computed(() => {
  if (nav.value.next) {
    let link: any = {
      name: "Profile.Media.Media",
      params: { username: profileData.value.username, id: nav.value.next._id }
    }
    if (context.value === "c") {
      link.query = { ctx: "c" }
    }
    return link
  }
  return undefined
})

const prevLink = computed(() => {
  if (nav.value.prev) {
    let link: any = {
      name: "Profile.Media.Media",
      params: { username: profileData.value.username, id: nav.value.prev._id }
    }
    if (context.value === "c") {
      link.query = { ctx: "c" }
    }
    return link
  }
  return undefined
})

const keydownHandler = (e: KeyboardEvent) => {
  const activeElement = document.activeElement;

  // Check if the active element is contenteditable or its ancestor is contenteditable
  const isContentEditable = activeElement?.hasAttribute('contenteditable') ||
    activeElement?.closest('[contenteditable="true"]') !== null;

  // Check if the active element is an input or textarea
  const isInputActive = activeElement instanceof HTMLInputElement ||
    activeElement instanceof HTMLTextAreaElement;

  // Only navigate if the user is not interacting with an editable element
  if (!isContentEditable && !isInputActive) {
    if (e.key === "ArrowLeft" && nav.value.next) {
      router.push(nextLink.value)
    } else if (e.key === "ArrowRight" && nav.value.prev) {
      router.push(prevLink.value)
    }
  }
}

async function getMedia() {
  let path = `/users/${profileData.value._id}/media/${mediaID.value}`

  if (context.value === 'c') {
    path += `?${new URLSearchParams({
      context: 'collection'
    })}`
  }

  try {
    const response = await API().get(path)
    media.value = response.data.data
    nav.value = response.data.navigation
    content.contentAuthor = media.value.user
    content.contentId = media.value._id
    content.contentType = "media"
    content.contentAudience = media.value.visibility
    audience.value = media.value.visibility
    description.value = media.value.description
    raw.value = media.value.raw || ""
    tags.value = media.value.tags
    if (media.value.collection) {
      collection.value = media.value.collection
    }
  } catch (err: any) {
    if (err.response && err.response.status === 403) {
      return router.push(`/${profileData.value.username}/media`)
    } else if (err.response && err.response.status === 404) {
      return router.push(`/${profileData.value.username}/media`)
    } else {
      console.error(err)
    }
  }
}

async function deleteMedia() {
  if (deleting.value) return
  if (confirm("Are you sure you want to delete this photo?")) {
    deleting.value = true
    try {
      await API().delete(`/media/${media.value._id}`)
      router.push(`/${profileData.value.username}/media`)
    } catch (err) {
      console.error(err)
      alert("There was an error deleting the photo.")
    }
  } else {
    return
  }
}

async function updateMedia() {
  if (updating.value) return
  updating.value = true
  try {
    await API().put(`/media/${media.value._id}`, {
      description: description.value,
      raw: raw.value,
      tags: tags.value
    })
    editing.value = false
    updating.value = false
  } catch (err) {
    console.error(err)
    alert("There was an error updating the photo.")
    updating.value = false
  }
}

async function addToCollection(payload: { collectionOnly: boolean }) {
  try {
    let path = `/users/${profileData.value._id}/media/${mediaID.value}`

    if (payload.collectionOnly) {
      path += `?${new URLSearchParams({
        context: 'collection'
      })}`
    }

    const response = await API().get(path)
    const revisedData = response.data.data
    if (revisedData.collection) {
      collection.value = revisedData.collection
    }
    if (payload.collectionOnly) {
      // add ctx to the current url
      const url = new URL(window.location.href)
      url.searchParams.set('ctx', 'c')
      window.history.replaceState({}, '', url.toString())
    }
    nav.value = response.data.navigation
    toggleCollectionModal()
  } catch (err) {
    console.error(err)
  }
}

async function removeFromCollection() {
  try {
    let path = `/users/${profileData.value._id}/media/${mediaID.value}`

    if (context.value) {
      // remove ctx from the current url
      const url = new URL(window.location.href)
      url.searchParams.delete('ctx')
      window.history.replaceState({}, '', url.toString())
    }

    const response = await API().get(path)
    nav.value = response.data.navigation
    collection.value = undefined
    toggleCollectionModal()
  } catch (err) {
    console.error(err)
  }
}

async function changeAudience() {
  try {
    let path = `/users/${profileData.value._id}/media/${mediaID.value}`

    if (context.value) {
      path += `?${new URLSearchParams({
        context: 'collection'
      })}`
    }

    const response = await API().get(path)
    const revisedData = response.data.data
    if (revisedData.collection) {
      collection.value = revisedData.collection
    }
    nav.value = response.data.navigation
    content.contentAudience = response.data.data.visibility
    audience.value = response.data.data.visibility
    toggleChangeAudienceModal()
  } catch (err) {
    console.error(err)
  }
}

function openReportDialog() {
  Platform.toggleReportOpen()
  Platform.setReportData({
    userId: media.value.user,
    contentId: media.value._id,
    contentType: "media"
  })
}

function openModerationDialog() {
  Platform.setModerationData({
    context: "media",
    userId: media.value.user,
    username: profileData.value.username,
    mediaId: media.value._id
  })
  Platform.toggleModerationOpen()
}
</script>

<template>
  <section class="px-2">
    <div class="container mx-auto Profile Media">
      <div class="relative overflow-hidden flex items-center justify-center bg-neutral-50 dark:bg-submit-925/50 h-[80vh] max-h-[80vh]">
        <img v-if="media" :alt="`Photo from ${profileData.username}.`" class="pointer-events-none select-none max-h-[80vh] w-auto max-w-full object-contain" :height="media.height" :width="media.width" :src="media.url" />
        <LoadIndicator v-else />
        <!-- <router-view :key="`constant-key`" /> -->
        <nav class="absolute top-0 bottom-0 right-0 left-0 z-20" v-if="media">
          <router-link v-if="nextLink" aria-label="Newer" tabindex="0" class="pl-4 absolute top-0 bottom-0 left-0 h-full flex items-center w-2/6 bg-gradient-to-r dark:from-black/10 dark:to-black/0 from-white/10 to-white/0  opacity-0 outline-0 transition-opacity hover:opacity-100 active:opacity-100" :to="nextLink">
            <svg class="w-8 h-8 dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"/></svg>
          </router-link>
          <router-link v-if="prevLink" aria-label="Older" tabindex="0" class="pr-4 absolute top-0 bottom-0 right-0 h-full flex items-center justify-end w-2/6 bg-gradient-to-l dark:from-black/10 dark:to-black/0 from-white/10 to-white/0 opacity-0 outline-0 transition-opacity hover:opacity-100 active:opacity-100" :to="prevLink">
            <svg class="w-8 h-8 dark:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/></svg>
          </router-link>
        </nav>
      </div>
      <article class="grid grid-cols-1 bg-neutral-50 dark:bg-submit-925 rounded-b-md" v-if="media">
        <header class="grid grid-cols-2 text-sm font-semibold divide-x-4 dark:divide-submit-950 divide-neutral-125 border-b-4 border-neutral-125 dark:border-submit-950" v-if="nextLink || prevLink">
          <router-link v-if="nextLink" :to="nextLink" class="block text-center py-2 dark:hover:bg-submit-800 hover:bg-white">Newer</router-link>
          <div class="text-center py-2 dark:text-gray-500" v-else>Newest</div>
          <router-link v-if="prevLink" :to="prevLink" class="block text-center py-2 dark:hover:bg-submit-800 hover:bg-white">Older</router-link>
          <div class="text-center py-2 dark:text-gray-500" v-else>Oldest</div>
        </header>
        <div class="pt-4 px-2 lg:px-0 w-full max-w-3xl mx-auto text-sm space-y-2">
          <div class="flex items-center gap-4" v-if="collection">
            <router-link :to="`/${profileData.username}/media/collections/${collection.slug}`" class="inline-flex items-center gap-2">
              <svg class="w-3 h-3 dark:fill-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M448 480H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64zM176 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm120 0c-8 0-15.5 4-20 10.7l-56 84L202.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h80 48H392c8.9 0 17-4.9 21.2-12.7s3.7-17.3-1.2-24.6l-96-144C311.5 228 304 224 296 224z"/></svg>
              {{ collection ? collection.title : ''}}
            </router-link>
            <button type="button" class="text-gray-400 hover:dark:text-white hover:text-black" @click="toggleCollectionModal('remove')" v-if="isOwner && collection">Remove</button>
          </div>
          <div v-if="isOwner && !collection">
            <button type="button" class="text-gray-400 hover:dark:text-white hover:text-black" @click="toggleCollectionModal('add')">Add to Collection</button>
          </div>
          <AddRemove v-if="isOwner" :open="collectionModal" :action="collectionAction" type="media" :content="media" :collection="collection" :toggleModal="toggleCollectionModal" @added="addToCollection" @removed="removeFromCollection" />
          <div @click="handleMentionClick" class="CommonBody" v-if="!editing" v-html="description && description !== '<p></p>' && description.length > 0 ? processedContent : 'No caption was provided, how sad.'"></div>
          <div v-if="editing">
            <Label field="description" v-if="editing">Caption</Label>
            <Editor v-if="editing" name="description" v-model="description" editor-style="alt" :options="editorOptions" @raw="updateRaw" />
          </div>
          <div v-if="!editing">
            <div>
              <ul class="inline">
                <li class="inline font-bold">Tags: </li>
                <li v-for="(tag, i) in media.tags" :key="tag" class="inline" v-if="media.tags && media.tags.length > 0">
                  {{ tag }}{{ i < media.tags.length - 1 ? ', ' : '' }}
                </li>
                <li class="inline" v-else>
                  None
                </li>
              </ul>
            </div>
            <template v-if="isOwner">
              <p class="text-xs mt-2">Shared with <button @click="toggleChangeAudienceModal" class="inline underline hover:decoration-gold-700 dark:hover:decoration-gold-500 decoration-2">{{ visibilityLabel }}</button> <time class="inline" :title="D(media.createdAt).format('LLLL')">{{ D(media.createdAt).fromNow() }}</time>.</p>
              <ChangeAudience :open="changeAudienceModal" type="media" :content="media" :toggleModal="toggleChangeAudienceModal" @changed="changeAudience" />
            </template>
            <template v-else>
              <p class="text-xs mt-2">Shared with {{ visibilityLabel }} <time class="inline" :title="D(media.createdAt).format('LLLL')">{{ D(media.createdAt).fromNow() }}</time>.</p>
            </template>
          </div>
          <div v-if="editing">
            <Label field="tags" v-if="editing">Tags</Label>
            <Tags
              v-if="editing"
              name="tags"
              :value="tags"
              v-model="tags"
              :limit="6"
              :alt="true"
            />
          </div>
          <div class="flex gap-4 justify-end" v-if="editing">
            <button type="button" @click="editing = false">Cancel</button>
            <button type="button" @click="updateMedia">{{ updating ? 'Updating...' : 'Update' }}</button>
          </div>
        </div>
        <footer class="mt-4 grid grid-cols-2 text-sm py-2 px-2 border-t-4 border-neutral-125 dark:border-submit-950">
          <div>
            <ReactV2 :self="media.self" :reacted="media.hasReacted ? media.hasReacted : false" :reactions="media.reactionsCount" :reaction-data="media.reactionData ? media.reactionData : undefined" :content="content" />
          </div>
          <!-- <div class="self-center">{{ media.commentCount }} Comment{{ media.commentCount === 1 ? '' : 's' }}</div> -->
          <div class="flex self-center place-self-end gap-4">
            <button v-if="isOwner" class="flex gap-2 items-center group dark:hover:text-white" @click="editing = true" type="button">
              <figure
                :class="[
                  'flex flex-none w-3 h-3 justify-center items-center'
                ]"
              >
                <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/></svg>
              </figure>
              Edit
            </button>
            <button v-if="isOwner" class="flex gap-2 items-center group dark:hover:text-white" @click="deleteMedia" type="button">
              <figure
                :class="[
                  'flex flex-none w-3 h-3 justify-center items-center'
                ]"
              >
                <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"/></svg>
              </figure>
              {{ deleting ? 'Deleting...' : 'Delete'}}
            </button>
            <button v-if="!isOwner" class="flex gap-2 items-center group dark:hover:text-white" @click="openReportDialog" type="button">
              <figure
                :class="[
                  'flex flex-none w-3 h-3 justify-center items-center'
                ]"
              >
                <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/></svg>
              </figure>
              Report
            </button>
            <button v-if="!isOwner && isAdmin" class="flex gap-2 items-center group dark:hover:text-white" @click="openModerationDialog" type="button">
              <figure
                :class="[
                  'flex flex-none w-3 h-3 justify-center items-center'
                ]"
              >
                <svg class="fill-gray-400 dark:fill-gray-500 group-hover:fill-black dark:group-hover:fill-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M230.1 .8l152 40c8.6 2.3 15.3 9.1 17.3 17.8s-1 17.8-7.8 23.6L368 102.5v8.4c0 10.7-5.3 20.8-15.1 25.2c-24.1 10.8-68.6 24-128.9 24s-104.8-13.2-128.9-24C85.3 131.7 80 121.6 80 110.9v-8.4L56.4 82.2c-6.8-5.8-9.8-14.9-7.8-23.6s8.7-15.6 17.3-17.8l152-40c4-1.1 8.2-1.1 12.2 0zM227 48.6c-1.9-.8-4-.8-5.9 0L189 61.4c-3 1.2-5 4.2-5 7.4c0 17.2 7 46.1 36.9 58.6c2 .8 4.2 .8 6.2 0C257 114.9 264 86 264 68.8c0-3.3-2-6.2-5-7.4L227 48.6zM98.1 168.8c39.1 15 81.5 23.2 125.9 23.2s86.8-8.2 125.9-23.2c1.4 7.5 2.1 15.3 2.1 23.2c0 70.7-57.3 128-128 128s-128-57.3-128-128c0-7.9 .7-15.7 2.1-23.2zM134.4 352c2.8 0 5.5 .9 7.7 2.6l72.3 54.2c5.7 4.3 13.5 4.3 19.2 0l72.3-54.2c2.2-1.7 4.9-2.6 7.7-2.6C387.8 352 448 412.2 448 486.4c0 14.1-11.5 25.6-25.6 25.6H25.6C11.5 512 0 500.5 0 486.4C0 412.2 60.2 352 134.4 352zM352 408c-3.5 0-6.5 2.2-7.6 5.5L339 430.2l-17.4 0c-3.5 0-6.6 2.2-7.6 5.5s.1 6.9 2.9 9L331 454.8l-5.4 16.6c-1.1 3.3 .1 6.9 2.9 9s6.6 2 9.4 0L352 470.1l14.1 10.3c2.8 2 6.6 2.1 9.4 0s4-5.7 2.9-9L373 454.8l14.1-10.2c2.8-2 4-5.7 2.9-9s-4.2-5.5-7.6-5.5l-17.4 0-5.4-16.6c-1.1-3.3-4.1-5.5-7.6-5.5z"/></svg>
              </figure>
              Moderation
            </button>
          </div>
        </footer>
      </article>
    </div>
    <div class="mb-6" v-if="media">
      <Comments size="medium" type="media" :forAuthor="media.user" :forAudience="audience ? audience : media.visibility" :content="media._id" :locked="media.commentsLocked ? media.commentsLocked : false" />
    </div>
  </section>
</template>
